
   <template>
   <div>
      <div class="headerbg">
          <b-container>
         <h1>常見問題</h1>
          </b-container>
      </div>
   <b-container>
      
      
      <div class="accordion mb-5" role="tablist">
      <b-card no-body class="mb-1">
         <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="link"
            >會員相關</b-button
            >
         </b-card-header>
         <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
         >
            <b-card-body>
            <b-card-text>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>申請證明</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>學員可於課程結束後至一年內，至櫃檯付費申請，製作時間為7個工作天至櫃台領取。
本部提供「出席證明」及「成績證明」申請，每份10元。亦提供「修讀證明」，每份50元（語言推廣班限修畢該系列課程最高級數方可申請修讀證明）。</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>上課座位是否有固定位置？
</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>本部採自由入座，並無固定座位。</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>學分班是否有規定一學期要修多少學分？</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>A學分班之課程為學員自行安排，本部並無強制的選讀學分下限規定。

選讀學分上限：選修學士程度學分班者，每學期累計修習之學分至多以修18學分為原則；碩士程度學分班者，每學期累計修習之學分至多以修9學分為原則。</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>上課教室於何時何地可以查詢？
</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>教室會於開課前一天於推廣部網頁的最新消息公布，可自行上網查看或於開課日當天至櫃台旁公佈欄查詢課表。但因恐現場人多擁擠，建議仍以網路查詢為主。</div>
               </div>
            </b-card-text>
            </b-card-body>
         </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
         <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="link"
            >課程相關</b-button
            >
         </b-card-header>
         <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
            <b-card-text>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>問題</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>答案</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>問題</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>答案</div>
               </div>
            </b-card-text>
            </b-card-body>
         </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
         <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="link"
            >其他</b-button
            >
         </b-card-header>
         <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
            <b-card-text>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>問題</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>答案</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>問題</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>答案</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>問題</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>答案</div>
               </div>
               <div class="qanda">
                  <div class="question"><b-icon icon="question-circle-fill"  variant="danger"></b-icon> <span>問題</span></div>
                  <div class="amswer"><b-icon icon="chat-dots-fill"  variant="success"></b-icon>答案</div>
               </div>
            </b-card-text>
            </b-card-body>
         </b-collapse>
      </b-card>
      </div>
   </b-container>
   </div>
   </template>
   <script>
   export default {
      name:'QandA',
      data(){
         return{}
      },
      mounted(){
         let navBgSwitch=this._storeData('navBgSwitch')
         if(this.IsNull(navBgSwitch)!=''){
            navBgSwitch(false)
         }
      }
   }
   </script>